import providerAppServices from '../../../services/provider/application'

import Infos from '../../share/application-setting/Infos'
import Domains from '../../share/application-setting//Domains'
import Settings from '../../share/application-setting//Settings'
import Acknowledgment from '../../share/application-setting//Acknowledgment'
import Completion from '../../share/application-setting//Completion'
import Aggrement from '../../share/application-setting//Aggrement'

export default {
    components: {
        Infos,
        Domains,
        Settings,
        Acknowledgment,
        Completion,
        Aggrement
    },
    data() {
        return {
            item: {},
            initialItem: {},
            key: 0,
            mountedKey: 0,
            services: providerAppServices,
            hasUnsavedChanges: false,
            showPopup: false,
            nextRoute: null,
        }
    },
    created(){
        this.getProfile(false)
        this.getProfileO()
        if(localStorage.getItem('updatecompleted')=='Y')
        {
            this._showToast('Update Completed!')
            localStorage.removeItem('updatecompleted')
        }
    },
    computed: {
        clonedObjectA() {
            return JSON.parse(JSON.stringify(this.initialItem))
        },
    },
    methods: {
        getProfile(showToast) {
            this.services.getProfile().then(resp => {
                if (!resp.error) {
                    this.key += 1
                    this.$nextTick(() => {
                        this.item = resp.data.d
                        this.$store.state.organization.is_centralized_setting_enabled = resp.data.d?.centralizedintake?.is_setting_enabled
                        this.mountedKey += 1
                    })
                    if(showToast)
                    {
                        localStorage.setItem('updatecompleted','Y')
                        window.location.reload(true)
                    }
                }
            })
        },
        getProfileO() {
            this.services.getProfile().then(resp => {
                if (!resp.error) {
                    this.initialItem = resp.data.d
                }
            })
        },
        onCompleted() {
            this.getProfile(false)
        },
        async save(showToast) {
            await this.$refs.Infos.save()
            await this.$refs.Domains.save()
            await this.$refs.Settings.save()
            await this.$refs.Settings.updateCentralizedSetting()
            await this.$refs.Settings.saveCentralizedSetting()
            await this.$refs.Settings.saveRegistrationSetting()
            await this.$refs.Acknowledgment.save()
            await this.$refs.Completion.save()
            this.getProfile(true)
            this.getProfileO()
            if (showToast && this.nextRoute) {
                this.$router.push(this.nextRoute)
            }
        },
        leavePage() {
            this.$router.push(this.nextRoute)
        },
        cancel() {
            this.getProfile(false)
            this.getProfileO()
        },
        compareObjects(obj1, obj2) {
            return JSON.stringify(obj1) !== JSON.stringify(obj2)
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.hasUnsavedChanges) {
            this.showPopup = true
            this._showConfirm('Are you sure you want to leave this page?<br/>Changes you made will not be saved.', { title: 'Unsaved Changes', variant: 'warning' ,saveLabel: 'Save'}).then(confirm => {
                if (confirm) {
                    this.save(true).then(() => next())
                }
                else
                {
                    this.leavePage()
                    next()
                }
            })
        } else {
            next()
        }
    }
}