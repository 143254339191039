import {getUserType} from '../../../../misc/auth'
import ViewModal from '../../../../components/part/shared/PreviewViewModal'
export default {
    components: {
        ViewModal
    },
    props: {
        item: { type: Object },
        initialItem: { type: Object },
        mKey: { type: Number },
        services: {type: Object}
    },
    data() {
        return {
            dataPhoto: null,
            userType: getUserType(),
            viewModalKey: 0,
        }
    },
    watch: {
        mKey() {
            this.$nextTick(()=>{
                if (this.item.info.profile_photo) {
                    this.dataPhoto = 'data:image;base64,' + this.item.info.profile_photo
                }
            })
        },
        item: {
            handler(newVal) {
                this.$parent.hasUnsavedChanges = this.compareObjects(newVal, this.initialItem)
            },
            deep: true
        },
    },
    methods: {
        forceRerenderView() {
            this.$refs.modal.show()
        },
        async View(dataurl) {
            dataurl.signed_url= dataurl.signed_url +'#toolbar=0&navpanes=0&scrollbar=0&statusbar=0'
            this.ViewClick(dataurl)
        },
        ViewClick(val) {
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(val)
            })
        },
        download(val) {
            if (val.signed_url) {
                this.openExternalLink(val.signed_url +'#toolbar=0&navpanes=0&scrollbar=0&statusbar=0')
            } else {
                this._showToast('Nothing to download', {variant: 'danger'})
            }
        },
        compareObjects(obj1, obj2) {
            return JSON.stringify(obj1) !== JSON.stringify(obj2)
        }
    }
}